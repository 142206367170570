export default {
  button: {
    size: {
      larger: "px-10 py-4",
      large: "px-5 py-3",
      regular: "px-4 py-2 text-sm bg-transparent",
      small: "px-3 py-1 text-sm",
      icon: {
        larger: "p-4",
        large: "p-3",
        regular: "p-2",
        small: "p-2",
      },
    },
    primary: {
      base: "bg-transparent btn-save",
      active:
        "active:bg-blue-800 hover:bg-blue-700 focus:ring focus:ring-blue-300",
      disabled: "opacity-50 cursor-not-allowed",
    },
    outline: {
      base: "bg-transparent btn-main",
      active:
        "active:bg-blue-800 hover:bg-blue-700 focus:ring focus:ring-blue-300",
      disabled: "opacity-50 cursor-not-allowed",
    },
  },
  tableHeader: {
    base: "table-head text-md font-semibold tracking-wide text-left text-gray-500 border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800",
  },
  tableCell: {
    base: "px-6 py-3",
  },
  tableContainer: {
    base: "w-full overflow-hidden ring-1 ring-black ring-opacity-5",
  },
  input: {
    base: "block text-sm focus:outline-none dark:text-gray-300 leading-5 h-10 border w-full",
  },
  modal: {
    base: "w-full py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl modal-main",
  },
  modalFooter: {
    base: "flex flex-col items-center justify-end px-6 py-3 -mb-4 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50 dark:bg-gray-800",
  },
  modalHeader: {
    base: "mb-2 text-lg font-semibold text-gray-700 dark:text-gray-300",
  },
};

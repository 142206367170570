import React, { useState, useMemo } from 'react';

// create context
export const SidebarContext = React.createContext();

export const SidebarProvider = ({ children }) => {
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  function toggleSidebarExpanded() {
    setIsSidebarExpanded(!isSidebarExpanded);
  }

  function setSidebarExpanded(value) {
    setIsSidebarExpanded(value);
  }

  function toggleSidebar() {
    setIsSidebarOpen(!isSidebarOpen);
  }

  function closeSidebar() {
    setIsSidebarOpen(false);
  }

  const value = useMemo(
    () => ({
      isSidebarOpen,
      toggleSidebar,
      closeSidebar,
      isSidebarExpanded,
      toggleSidebarExpanded,
      setSidebarExpanded,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSidebarOpen, isSidebarExpanded],
  );

  return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>;
};
